<template>
  <div class="relative p-normal pb-14">
    <Logo></Logo>
    <a-scroll />
    <div class="politics">
      <h1 class="privacy_tittle">Politicas de privacidad</h1>
      <div class="politics-terms-container" >
        <small>Última actualización: 31 de mayo de 2021</small>
        <ol>
          <li>
            <h2>- Responsable y descripción</h2>
            <ol>
              <li>
                <b>- Responsable</b><br>
                El responsable del tratamiento de este sitio es Grupo Gastrojoyas S.L. (en adelante  SoloFoodies), con domicilio en Calle de Huelva, 16 - LOFT 50, CP 28100, Alcobendas, Madrid.<br><br>
                NIF: B88487327<br>
                Correo electrónico: <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>
              </li>
              <li>
                <b>- Descripción</b><br>
                La aceptación de la política de privacidad de SoloFoodies (en adelante “Política de
                Privacidad”), es condición necesaria para el uso de nuestra web, redes sociales y la prestación del servicio (en adelante el “Servicio”).
                <br><br>
                Esta Política de Privacidad regula la recopilación, tratamiento y uso de tu información
                personal y no personal como usuario de SoloFoodies, a partir de la fecha de entrada
                en vigor que aparece en el encabezado.
                <br><br>
                Para tratar tus datos personales, SoloFoodies cumple con la legislación vigente, local y
                europea, así como su normativa de desarrollo.
              </li>
            </ol>
          </li>
          <li>
            <h2>- Información recopilada</h2>
            El Servicio puede usarse sin necesidad de proporcionar ningún dato, si bien para darte de alta como usuario en nuestra plataforma (como influencer o negocio) o para realizar las colaboraciones necesitarás indicarnos algunos datos personales. La información (personal o no personal) recopilada por el Servicio puede variar en función de eso.
            <br><br>
            La información personal y no personal recopilada por el Servicio nos llegará por tres vías: <b>1)</b>
            la recopilada automáticamente, <b>2)</b> la que nos proporciones voluntariamente y <b>3)</b> la
            proporcionada por terceros.
            <ol>
              <li>
                <b>- La recopilada automáticamente</b><br>
                Esta información consistirá en:<br>
                <ul>
                  <li>La recopilada mediante cookies o mecanismos similares almacenados en tu dispositivo, siempre con tu consentimiento o de acuerdo a otras bases legales.Consulta nuestra <a href="#" class="text-primary" target="_blank">Política de Cookies</a> para más información.</li>
                  <li>La IP desde la que se realiza la conexión, el tipo de dispositivo usado y sus características, la versión del sistema operativo, el tipo de navegador, el idioma, la fecha, el país, la hora de la solicitud, la URL desde la que procedes, la URL a la que marchas o la red móvil empleada, entre otros.</li>
                  <li>Datos de uso del Servicio y posibles errores detectados durante su utilización.</li>
                  <li>Además, SoloFoodies utiliza Google Analytics, un servicio analítico prestado por Google, LLC. Para la prestación de estos servicios, estos utilizan cookies que recopilan la información, incluida la dirección IP del usuario, que será transmitida, tratada y almacenada por Google en los términos fijados en la web www.google.com. Incluyendo la posible transmisión de dicha información a terceros por razones de exigencia legal o cuando dichos terceros procesen la información por cuenta de Google.<br><br>
                  En cualquier caso, puedes inhabilitar las cookies de Google Analytics desde <a href="https://tools.google.com/dlpage/gaoptout" class="text-primary">aquí</a>.
                  </li>
                </ul>
              </li>
              <li><b>- La que proporcionas voluntariamente</b><br>
                Esta información consistirá en:<br>
                <ul>
                  <li>
                    La requerida por SoloFoodies para llevar a cabo el registro como usuario negocio: nombre del negocio y contraseña.<br><br>
                    Todos estos datos tienen carácter obligatorio.<br><br>
                    Posteriormente podrás completar tu perfil con información adicional como por ejemplo nombre de la persona de contacto, teléfono, dirección del negocio y correo electrónico, entre otros.
                  </li>
                </ul>
                En relación a la contraseña, como usuario del Servicio serás responsable de su protección. En ese sentido, asumirás cualquier daño y perjuicio que pudiera derivarse de su uso indebido o de la cesión de la misma a terceras personas.
                <ul>
                  <li>
                    La requerida por SoloFoodies para llevar a cabo el registro como usuario influencer: nombre y apellido, correo electrónico y contraseña.<br><br>
                    Todos estos datos tienen carácter obligatorio.<br><br>
                    Posteriormente podrás completar tu perfil con información adicional y enlazar a tu cuenta de Instagram.
                  </li>
                </ul>
                En relación a la contraseña, como usuario del Servicio serás responsable de su protección. En ese sentido, asumirás cualquier daño y perjuicio que pudiera derivarse de su uso indebido o de la cesión de la misma a terceras personas.
                <ul>
                  <li>
                    La requerida por SoloFoodies en el registro de usuario como negocio o influencer si te logueas a través de tu perfil de Facebook. En este caso recibiremos los datos de tu perfil público consistentes en tu nombre, género, nombre de usuario e identificador de usuario (número de cuenta), intervalo de edad, idioma, país, correo electrónico, foto del perfil, foto de portada, dirección y redes (con sus correspondientes métricas y engagement como por ejemplo likes, seguidores o ubicación).
                  </li>
                  <li>
                    La información, personal o no, que pudieran contener las valoraciones realizadas a los influencers por parte de los negocios, como por ejemplo nombre y apellidos y mensaje/valoración.
                  </li>
                  <li>
                    La información, personal o no, que pudieran contener los mensajes enviados a través de los canales de contacto establecidos en el Servicio, por ejemplo el asunto, dirección de correo electrónico, archivo adjunto y mensaje.
                  </li>
                  <li>
                    La información de pago requerida para poder adquirir el servicio.
                  </li>
                  <li>
                    Tu localización ofrecida en tu dispositivo electrónico para poder llevar a cabo satisfactoriamente el Servicio prestado.
                  </li>
                </ul>
              </li>
              <li>
                <b>- La proporcionada por terceros</b><br>
                Esta información consistirá en:<br>
                <ul>
                  <li>
                    La proporcionada por sistemas de pago o procesadoras de tarjetas de crédito, como la hora de la compra o su importe.
                  </li>
                  <li>
                    La proporcionada por redes sociales o servicios similares
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <h2>- Derechos y finalidades</h2>
              Te informamos que la cumplimentación de los formularios tiene carácter voluntario. Ahora
              bien, si no rellenas los campos obligatorios (marcados normalmente con un asterisco), el
              uso de algunas funciones del Servicio no será posible o se verá limitado.<br><br>

              Los datos personales que nos facilites quedan incorporados y serán tratados en el Registro
              de Actividades de Tratamiento de SoloFoodies, con el fin de poder atender tus peticiones, prestar el servicio solicitado y mantenerte informado sobre cuestiones relativas a la actividad de la empresa y sus servicios.<br><br>

              Puedes ejercer en cualquier momento los derechos de acceso, rectificación, supresión,
              limitación de tu tratamiento, oposición y portabilidad de tus datos de carácter personal
              mediante correo electrónico dirigido a: <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a> O la dirección postal:
              Calle de Huelva, 16 - LOFT 50, CP 28100, Alcobendas, Madrid.<br><br>

              En ambos casos deberás identificarte con tu nombre y apellidos, además de una copia de tu
              DNI o ID nacional, si fuese necesario.<br><br>

              En el caso de que hayas otorgado el consentimiento para alguna finalidad específica, tienes derecho a retirar el consentimiento en cualquier momento, sin que ello afecte a la licitud del tratamiento basado en el consentimiento previo a su retirada.<br><br>

              Además, si consideras que hay un problema con la forma en que SoloFoodies está manejando tus datos, puedes dirigir tus reclamaciones
              a la <a class="text-primary" href="https://www.aepd.es/es/derechos-y-deberes/conoce-tus-derechos" target="_blank">autoridad de protección de datos</a>
              que corresponda, siendo la <a class="text-primary" href="https://www.aepd.es/es" target="_blank">Agencia Española de Protección de Datos</a>
              la indicada en el caso de España.
          </li>
          <li>
            <h2>- Edad</h2>
            En cuanto al uso del servicio online, declaras que eres mayor de edad y que dispones de la capacidad legal necesaria para vincularte por este acuerdo y utilizar el sitio de conformidad con sus términos y condiciones, que comprendes y reconoces en su totalidad.
            <br><br>
            Si contratas en nombre de una empresa el Servicio, reconoces disponer de autorización y la representación adecuada en nombre de la organización para ello.
            <br><br>
            Declaras que toda la información que proporciones para acceder al Servicio, antes y durante su utilización, es verdadera, completa y precisa.
          </li>
          <li>
            <h2>- Uso de los datos</h2>
            SoloFoodies usará los datos recopilados para:<br>
            <ul>
              <li>
                Administrar, proporcionar y actualizar el Servicio (siendo la base legal nuestro interés legítimo en mantener y conservar el Servicio al día y en buen estado).
              </li>
              <li>
                Atender las cuestiones que plantees (siendo la base legal nuestro interés legítimo en atender las peticiones y dudas de nuestros usuarios).
              </li>
              <li>
                Procesar los pagos que realices (siendo la base legal el contrato).
              </li>
              <li>
                Proporcionar el servicio contratado como negocio (siendo la base legal el contrato).
              </li>
              <li>
                Proporcionar el servicio contratado como influencer (siendo la base legal el contrato).
              </li>
              <li>
                Gestionar y procesar las colaboraciones entre negocio e influencer (siendo la base legal tu consentimiento).
              </li>
              <li>
                Enviarte mediante correo electrónico nuestro boletín informativo, novedades y notificaciones relacionadas con avances o cambios en las colaboraciones o contrataciones realizadas (siendo la base legal, en función del caso, la relación contractual o nuestro interés legítimo basado en la previa relación contractual, de acuerdo al art. 21.2 LSSICE).
              </li>
            </ul>
            Podrás darte de baja de los mismos desde el propio correo electrónico recibido o contactando con nosotros en <a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>. No obstante, no podrás darte de baja de determinada correspondencia nuestra, como los mensajes relativos a la seguridad de tus datos o los términos y condiciones del Servicio.
            <ul>
              <li>
                Mantener la seguridad del Servicio, investigar actividades ilícitas, hacer cumplir nuestros términos y condiciones y ayudar a los cuerpos y fuerzas de seguridad del estado en el marco de sus eventuales investigaciones (siendo la base legal el nuestro interés legítimo en garantizar y mantener la seguridad del Servicio y sus usuarios).
              </li>
            </ul>
            SoloFoodies no usa decisiones automatizadas, aunque puede generar perfiles básicos de los usuarios, siendo la base legal nuestro interés legítimo para finalidades comerciales y proporcionar ofertas personalizadas.
            <br><br>
            Asimismo, SoloFoodies podrá utilizar la información de los usuarios en forma de datos agregados y anónimos para mostrarlos a terceros. También podrá compartir estadísticas y
            la información demográfica sobre los usuarios y su utilización del Servicio con terceros. Pero nada de esto permitirá a esos terceros identificarte personalmente.
            <ol>
              <li>
                <b>- En mails y formularios de contacto</b><br>
                La web del Servicio cuenta con un cifrado TLS que permite al usuario el envío seguro
                de tus datos personales a través de formularios de contacto de tipo estándar.<br><br>

                Los datos personales recogidos serán objeto de tratamiento automatizado e incorporados a
                los correspondientes ficheros del registro de actividades de tratamiento y de los que SoloFoodies es titular.<br><br>

                En ese sentido:<br><br>
                <ul>
                  <li>
                    Nos llegará tu IP, que será usada para comprobar el origen del mensaje con objeto de ofrecerte recomendaciones adecuadas (por ejemplo presentar la
                    información en el idioma correcto) y para detectar posibles irregularidades (por ejemplo posibles intentos de ciberataque al Servicio),
                    así como datos relativos a tu ISP.
                  </li>
                  <li>
                    Asimismo, podrás facilitarnos tus datos a través de teléfono, correo electrónico y otros medios de comunicación indicados.
                  </li>
                </ul>
                Respecto a los medios de comunicación usados:<br><br>
                <ul>
                  <li>
                    Nuestros prestadores de servicios de pago para abonar la prestación de nuestros servicios o el importe de los productos son tarjeta de crédito o débito. En ningún momento el Servicio ni su personal tendrán acceso a los datos bancarios (por ejemplo, el número de tu cuenta) que el usuario como cliente facilitará a esos terceros.
                  </li>
                </ul>
                Por otro lado, ciertos servicios prestados a través de la web (por ejemplo, la posibilidad de
                participar en un concurso o sorteo de código promocional) pueden contener condiciones
                particulares en materia de protección de datos personales. Será necesario aceptarlas antes
                de participar en esos servicios.
              </li>
              <li>
                <b>.- En redes sociales</b><br>
                SoloFoodies cuenta con perfiles en algunas de las principales redes sociales, siendo responsable del tratamiento en relación con los datos publicados en las mismas (por ejemplo, fotos subidas por SoloFoodies en las que aparecen caras de personas).
                <br><br>
                El tratamiento que SoloFoodies llevará a cabo con los datos dentro de cada una de las referidas redes será, como máximo, el que la red social permita a los perfiles corporativos.
                <br><br>
                Así pues, SoloFoodies podrá informar, cuando la ley no lo prohíba, a nuestros seguidores por cualquier vía que la red social permita sobre sus actividades u ofertas, así como prestar un servicio personalizado de atención al cliente.
                <br><br>
                En ningún caso SoloFoodies extraerá datos de las redes sociales, a menos que se obtuviera puntual y expresamente el consentimiento del usuario para ello.
                <br><br>
                Por otro lado, SoloFoodies podrá compartir y publicar en redes sociales el contenido de los servicios ofrecidos por los establecimientos registrados en SoloFoodies por motivos publicitarios. A tal efecto, el establecimiento registrado autoriza a SoloFoodies, de forma gratuita, a reproducir, distribuir, publicitar, adaptar o explotar libremente, por cualquier forma o medio, el contenido publicado por el establecimiento registrado. De igual manera, el establecimiento registrado renuncia a cualesquiera derechos de contenido económico que le pudieran corresponder, en concepto de compensación o cualquier otro título.
                <br><br>
                Por último, los influencers registrados en la plataforma conceden al Servicio una licencia de uso del contenido que generen como consecuencia de la colaboración con los negocios registrados en SoloFoodies. En este sentido, SoloFoodies podrá compartir y publicar en redes sociales el contenido de estos influencers por motivos publicitarios. A tal efecto, el influencer registrado autoriza a SoloFoodies, de forma gratuita, a reproducir, distribuir, publicitar, adaptar o explotar libremente, por cualquier forma o medio, el contenido publicado por el influencer. De igual manera, el influencer registrado renuncia a cualesquiera derechos de contenido económico que le pudieran corresponder, en concepto de compensación o cualquier otro título
              </li>
              <li>
                <b>.- En sorteos</b><br>
                Al participar en acciones promocionales organizadas en exclusiva por SoloFoodies, los datos que cedas serán tratados únicamente por nosotros para el fin que se haya indicado en el enunciado de la acción y sus correspondientes condiciones legales.
              </li>
            </ol>
          </li>
          <li>
            <h2>- Conservación de los datos</h2>
            A continuación se indican por cuánto tiempo se conservan los datos tratados por el Servicio:
            <ul>
              <li>
                Los datos desagregados serán conservados sin plazo de supresión.
              </li>
              <li>
                Los datos de los clientes serán conservados el mínimo necesario, pudiendo mantenerse hasta:
                <ul>
                  <li>
                    5 años, según el art. 1964 del Código Civil (acciones personales sin plazo especial).
                  </li>
                  <li>
                    6 años, según el art. 30 del Código de Comercio (libros de contabilidad o factura, por ejemplo).
                  </li>
                </ul>
              </li>
              <li>
                Los datos de los usuarios serán conservados hasta un plazo máximo de 12 meses para poder atender a sus peticiones.
              </li>
              <li>
                Los datos de usuarios subidos por SoloFoodies a sus redes sociales se conservarán desde que el usuario ofrece su consentimiento, o el dato haya sido subido de acuerdo al interés legítimo de SoloFoodies, hasta que lo retira.
              </li>
            </ul>
          </li>
          <li>
            <h2>- Datos compartidos con terceros</h2>
            Hay terceros que gestionan parte del Servicio.<br><br>
            A ellos SoloFoodies les exige que cumplan esta Política de Privacidad en lo que les resulte aplicable, además de tener la suya propia. No obstante, SoloFoodies no será responsable del cumplimiento de dicha política.
            <br><br>
            Bajo algunas circunstancias, SoloFoodies podrá compartir, usar o preservar con terceros alguna de la información personal recopilada:
            <ul>
              <li>
                Para prestar el Servicio:
              </li>
            </ul>
            Los proveedores de servicios que prestan funciones en nuestro nombre, como por ejemplo los servicios de pago, el alojamiento web, la analítica comercial o el marketing. Estos proveedores de servicios pueden recopilar y tener acceso a la información que les sea necesaria para desempeñar sus funciones, pero no tienen permitido compartir o utilizar la información para ningún otro propósito.
            <ul>
              <li>
                Para cooperar con las autoridades competentes:
              </li>
            </ul>
            Si creemos que es razonablemente necesario para satisfacer cualquier ley, proceso legal o interés legítimo. En cualquier caso, sólo proporcionaremos la información estrictamente requerida.
          </li>
          <li>
            <h2>- Medidas de seguridad</h2>
            SoloFoodies adopta todas las medidas técnicas y organizativas necesarias para proteger la seguridad e integridad de la información personal y no personal recopilada. Tanto frente a accesos no autorizados como su alteración, pérdida o destrucción accidentales.
            <br><br>
            En todo caso, SoloFoodies no puede garantizar la seguridad absoluta de la información recopilada, por lo que debes colaborar y utilizar en todo momento el sentido común sobre la información compartida.
            <br><br>
            Entiendes y reconoces que, incluso después de su eliminación, la información personal y no
            personal puede permanecer visible en caché o si otros usuarios la han copiado o almacenado.
          </li>
          <li>
            <h2>- Cambios en la Política de Privacidad</h2>
            Podremos actualizar esta Política de Privacidad en el futuro.
            <br><br>
            Te informaremos sobre sus cambios enviando un aviso a la dirección de correo electrónico
            facilitada y/o colocando un aviso en un lugar prominente de nuestra web.
          </li>
          <li>
            <h2>- Contacto</h2>
            Si tienes dudas sobre esta Política de Privacidad, contacta con nosotros en:
            <br><br>
            E-mail:<a class="text-primary" href="mailto:info@solofoodies.com" target="_blank">info@solofoodies.com</a>
            Dirección: Calle de Huelva, 16 - LOFT 50, CP 28100, Alcobendas, Madrid
          </li>
        </ol>
      </div>
    </div>
  </div>
<!--  <Footer></Footer>-->
</template>

<script>
import Logo from '@/components/Logo'
// import Footer from '@/components/Footer'

export default {
  name: 'Politics',
  components: {
    // Footer,
    Logo
  }
}
</script>

<style lang="scss">
.politics-terms-container{
  text-align: justify;
  h2{
    font-size: 1.2em;
    font-weight: bold;
    margin-bottom:5px;
    display: block !important;
  }
  ol {
    list-style-type: none;
    counter-reset: item;
    margin: 0 0 10px 0;
    padding: 0;
  }
  ol > li {
    display: table;
    counter-increment: item;
    margin-bottom: 0.6em;
  }

  ol > li:before {
    content: counters(item, ".") ". ";
    display: table-cell;
    padding-right: 0.6em;
  }
  li ol > li {
    margin: 0;
  }
  li ol > li:before {
    content: counters(item, ".") " ";
  }
  ul{
    list-style: circle;
    padding-left: 1.5em;
    margin: 0 0 2em 0 !important;
  }
  .alphabet_ul{
    list-style: upper-latin !important;
  }
}
.privacy_tittle{
  font-size: 1.5em;
  margin-bottom: 10px;
  font-weight: 300;
  color: #000;
}
</style>
